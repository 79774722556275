<script setup lang="ts">
import { Footer } from '@upa/design-system';

const { navigationElements } = useNavigation({ type: 'footer-navigation' });
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const router = useRouter();
const { isProfessionalStore } = useStore();

const openCategory = (link: string | undefined) => {
  if (link) {
    router.push(formatLink(link));
  }
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
</script>

<template>
  <Footer
    :categories="navigationElements as any"
    :professional-shop="isProfessionalStore"
    @category-clicked="openCategory"
    @scrolls-to-top="scrollToTop"
  />
</template>
